import amplitude from 'amplitude-js'
import { getUserId, getUserEmail } from 'utils/TokenHelper'

export const amplitudeInit = () => {
  const ampl = amplitude.getInstance('FH Network')
  ampl.init(
    process.env.REACT_APP_AMPLITUDE_KEY!,
    getUserId(),
    { includeReferrer: true, saveEvents: true },
    function () {}
  )
  ampl.setVersionName(process.env.REACT_APP_VERSION!)
  window.ampl = ampl
  amplitudeLogin()
  logAmplitudeEvent('Session Start')
}

export const logAmplitudeEvent = (event: any, data?: any) => {
  if (window.isDev) console.log(event, data)
  if (window.ampl) {
    window.ampl.logEvent(event, data)
  }
}

export const amplitudeLogin = () => {
  const userId = getUserId()
  if (userId) {
    if (window.ampl) {
      window.ampl.setUserId(userId)
    }
  } else {
    if (window.ampl) {
      window.ampl.setUserId(null)
      window.ampl.regenerateDeviceId()
    }
  }
  const email = getUserEmail()
  if (getUserEmail()) setAmplitudeProfile({ email })
}

export const amplitudeLogout = () => {
  if (window.ampl) {
    window.ampl.setUserId(null)
    window.ampl.regenerateDeviceId()
  }
}

export const setAmplitudeOption = (key: any, value: any) => {
  const identify = new amplitude.Identify().set(key, value)
  if (window.ampl) {
    window.ampl.identify(identify)
  }
}

export const setAmplitudeProfile = (options: any) => {
  const { email } = options
  if (email) setAmplitudeOption('Email', email)
}
