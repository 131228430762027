import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { Helmet } from 'react-helmet'
import { useInterval } from 'react-use'

import { PreviewView } from './PreviewView'
import styles from './Upload.module.scss'
import { UploadView } from './UploadView'
import { UploadingView } from './UploadingView'
import {
  uploadCSVPromise,
  commitCSVPromise,
  getUploadsPromise,
} from 'apis/admin'

import { type RecentUpdates, type UploadStatus } from './types'
import { logAmplitudeEvent } from 'utils/amplitude'

interface UploadProps {}
type PageView = 'upload' | 'uploading' | 'preview'
export const formatFileSize = (size: number) => {
  const value = Number(size)
  if (size && !isNaN(value)) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
    let index = 0
    let k = value
    if (value >= 1024) {
      while (k > 1024) {
        k = k / 1024
        index++
      }
    }
    return `${k.toFixed(1)}${units[index]}`
  }
  return '-'
}

const useUpload = () => {
  const [view, setView] = useState<PageView>('upload')
  const uploadRef = useRef<HTMLInputElement>(null)

  const resetFile = () => {
    setFile(undefined)
    if (uploadRef?.current) uploadRef.current.value = ''
  }

  const [uploadProgress, setUploadProgress] = useState<number>(0)

  const [fileCommitProgress, setFileCommitProgress] = useState<number>(0)
  const [fileCommitDone, setFileCommitDone] = useState<boolean>(false)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({})

  const [file, setFile] = useState<
    | {
        name: string
        size: string
      }
    | undefined
  >()

  const chooseFile = useCallback(() => {
    uploadRef?.current?.click()
  }, [])

  const [recentUpdates, setRecentUpdates] = useState<RecentUpdates>([])

  const onSelectFile = async () => {
    try {
      const file = uploadRef?.current?.files?.[0]
      if (!file) return
      setFile({
        name: file.name,
        size: formatFileSize(file.size),
      })
      setUploadStatus({})
      setView('uploading')
      setUploadProgress(0)

      const resp = await uploadCSVPromise(file)
      const _error = resp.status !== 200
      setUploadStatus({
        uploadError: _error, //or equals 400
        uploadErrorCode: resp.status,
        pendingFile: resp.data,
      })
      setUploadProgress(100)

      setTimeout(() => {
        if (_error) {
          setView('upload')
        } else {
          setView('preview')
        }
      }, 2000)
    } catch (error: any) {
      setUploadStatus({
        uploadError: true,
        uploadErrorCode: error?.response?.data?.Code,
        pendingFile: {},
      })
      setView('upload')

      resetFile()
    }
  }

  const onCommitFile = async () => {
    try {
      setFileCommitProgress(1)
      setFileCommitDone(false)
      const resp = await commitCSVPromise(uploadStatus?.pendingFile?.uploadId)
      const _error = resp.status !== 200
      //  logAmplitudeEvent('FPP: Update File', {
      //   Result: 'Success',
      // })
      setFileCommitDone(true)
      setFileCommitProgress(100)
    } catch (error: any) {
      resetFile()
      //   logAmplitudeEvent('FPP: Update File', {
      //   'Result': 'Fail',
      //   'Error Message':error?.response?.data?.Message
      // })
    }
  }

  useInterval(
    () => {
      if (uploadProgress < 99) {
        setUploadProgress(uploadProgress + 1)
      }
    },
    view === 'uploading' ? 100 : null
  )

  useInterval(
    () => {
      if (fileCommitProgress < 99) {
        setFileCommitProgress(fileCommitProgress + 1)
      }
    },
    fileCommitProgress > 0 && fileCommitProgress < 100 ? 100 : null
  )
  const getUploads = useCallback(async () => {
    try {
      const data = await getUploadsPromise()
      setRecentUpdates(_.reverse(data?.results))
    } catch (e) {
      console.error(e)
    }
  }, [])
  useEffect(() => {
    if (view === 'upload') getUploads()
  }, [view, getUploads])

  // const recentUpdates = useMemo<RecentUpdates>(() => {
  //   return [
  //     {
  //       date: "18:52 PM 04/07/2022",
  //       uploader: "Kunald Smith",
  //       fileName: "File-50-members.csv",
  //       reimbursements: 239,
  //       id: 1,
  //     },
  //     {
  //       date: "18:52 PM 04/07/2022",
  //       uploader: "Kunald Smith",
  //       fileName: "File-50-members.csv",
  //       reimbursements: 239,
  //       id: 2,
  //     },
  //     {
  //       date: "18:52 PM 04/07/2022",
  //       uploader: "Kunald Smith",
  //       fileName: "File-50-members.csv",
  //       reimbursements: 239,
  //       id: 3,
  //     },
  //   ];
  // }, []);

  return {
    view,
    setView,
    file,
    setFile,
    chooseFile,
    onSelectFile,
    uploadRef,
    uploadStatus,
    recentUpdates,
    uploadProgress,
    fileCommitDone,
    setFileCommitDone,
    fileCommitProgress,
    setFileCommitProgress,
    onCommitFile,
  }
}

export const Upload: React.FC<UploadProps> = () => {
  const {
    view,
    setView,
    file,
    setFile,
    chooseFile,
    onSelectFile,
    uploadRef,
    uploadStatus,
    recentUpdates,
    uploadProgress,
    fileCommitDone,
    setFileCommitDone,
    onCommitFile,
    fileCommitProgress,
  } = useUpload()

  return (
    <div className={styles.container}>
      <Helmet title={'Upload'} />
      {view === 'upload' && (
        <UploadView
          {...{
            file,
            setFile,
            chooseFile,
            onSelectFile,
            uploadRef,
            uploadStatus,
            recentUpdates,
          }}
        />
      )}
      {view === 'uploading' && (
        <UploadingView
          {...{
            file,
            uploadProgress,
            uploadStatus,
            onClose: () => setView('upload'),
          }}
        />
      )}
      {view === 'preview' && (
        <PreviewView
          {...{
            file,
            chooseFile,
            uploadStatus,
            fileCommitDone,
            fileCommitProgress,
            onCommitFile,
            onClose: () => {
              setFileCommitDone(false)
              setView('upload')
            },
          }}
        />
      )}
    </div>
  )
}
