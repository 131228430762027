import apiInstance from "./api";

export const getLoginEmailPromise = async (email: string) => {
  const api = await apiInstance.getApi();
  const res = await api.post(`/admin/authentication`, {
    email,
  });
  return res.data?.uri;
};

export const getTemplatePromise = async (): Promise<string> => {
  const api = await apiInstance.getApi();
  const res = await api.get(`/admin/uploads/template`, {});
  return res.data as string;
};

export const uploadCSVPromise = async (file: File) => {
  const api = await apiInstance.getApi();
  var formData = new FormData();
  formData.append("file", file);
  const res = await api.post(`/admin/uploads`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const commitCSVPromise = async (id: string) => {
  const api = await apiInstance.getApi();
  const res = await api.post(`/admin/uploads/${id}`, {});
  return res;
};
export const getUploadsPromise = async () => {
  const api = await apiInstance.getApi();
  const res = await api.get(`/admin/uploads`, {});
  return res.data;
};

export const getUploadErrorPromise = async (url: string) => {
  const api = await apiInstance.getApi({
    // noPrefix: true,
  });
  const res = await api.get(url, {});
  return res.data;
};
export const autoLoginPromise = async ({ u, e, t, h }: any) => {
  const api = await apiInstance.getApi();
  const res = await api.post(`admin/authentication/token`, { u, e, t, h });
  return res;
};
