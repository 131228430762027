import { useAuthState } from 'contexts/AuthContext'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface RequireAuthProps {
  children: JSX.Element
}

export const RequireAuth: React.FC<RequireAuthProps> = (props) => {
  const { children } = props
  const navigate = useNavigate()
  const { isAuthenticated, isAuthLoading } = useAuthState()
  console.log('RequireAuth', { isAuthenticated, isAuthLoading })
  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading) {
      navigate('/login')
    }
  }, [isAuthenticated, isAuthLoading])

  if (isAuthenticated && !isAuthLoading) return children
  return null
}
