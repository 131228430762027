import queryString from 'query-string'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'
import { autoLoginPromise } from 'apis/admin'
import { setPFToken, getValidPFToken } from 'utils/TokenHelper'
import {
  amplitudeLogin,
  amplitudeLogout,
  logAmplitudeEvent,
} from 'utils/amplitude'
interface AuthContextState {
  isAuthLoading?: boolean
  isAuthenticated?: boolean
}

enum AuthContextReducerAction {
  SET_AUTH_STATUS = 'SET_AUTH_STATUS',
}

const initialState: AuthContextState = {
  isAuthLoading: true,
  isAuthenticated: false,
}

const reducer = (
  state: AuthContextState,
  action: { payload: AuthContextState; type: AuthContextReducerAction }
) => {
  switch (action.type) {
    case AuthContextReducerAction.SET_AUTH_STATUS:
      return {
        ...state,
        ...(action.payload || {}),
      }
    default:
      throw new Error()
  }
}

export const AuthContextProvider = (children: any) => {
  const Provider = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { u, e, t, h } = queryString.parse(window.location.search, {
      decode: false,
    })

    const autoLogin = useCallback(async () => {
      dispatch({
        type: AuthContextReducerAction.SET_AUTH_STATUS,
        payload: {
          isAuthLoading: true,
          isAuthenticated: false,
        },
      })
      amplitudeLogout()
      //auto login
      if (u && e && t && h) {
        try {
          const resp = await autoLoginPromise({ u, e, t, h })
          if (resp?.data?.token) {
            setPFToken(resp?.data?.token)
            dispatch({
              type: AuthContextReducerAction.SET_AUTH_STATUS,
              payload: {
                isAuthLoading: false,
                isAuthenticated: true,
              },
            })
            amplitudeLogin()
            logAmplitudeEvent('FPP: Log In', {
              Result: 'Success',
            })
          } else {
            logAmplitudeEvent('FPP: Log In', {
              Result: 'Fail',
            })
            dispatch({
              type: AuthContextReducerAction.SET_AUTH_STATUS,
              payload: {
                isAuthLoading: false,
                isAuthenticated: false,
              },
            })
          }
        } catch (e: any) {
          logAmplitudeEvent('FPP: Log In', {
            Result: 'Fail',
            Error: e?.response?.data?.Message,
          })
          dispatch({
            type: AuthContextReducerAction.SET_AUTH_STATUS,
            payload: {
              isAuthLoading: false,
              isAuthenticated: false,
            },
          })
        }
      } else {
        if (getValidPFToken()) {
          dispatch({
            type: AuthContextReducerAction.SET_AUTH_STATUS,
            payload: {
              isAuthLoading: false,
              isAuthenticated: true,
            },
          })
        } else {
          dispatch({
            type: AuthContextReducerAction.SET_AUTH_STATUS,
            payload: {
              isAuthLoading: false,
              isAuthenticated: false,
            },
          })
        }
      }
    }, [])

    useEffect(() => {
      autoLogin()
    }, [])

    return (
      <AuthContext.Provider
        value={{
          ...state,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
  return Provider
}
export const AuthContext = createContext<AuthContextState>({})

export const useAuthState = () => {
  const state = useContext(AuthContext)
  return state
}
