import { Link, Route, Routes } from "react-router-dom";

import { Login } from "views/Login";
import { Upload } from "views/Upload";

import { RequireAuth } from "components/RequireAuth";

export default function Routers() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Upload />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function NotFound() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
