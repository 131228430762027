import axios from 'axios'

import { getValidPFToken } from 'utils/TokenHelper'

const HOST = process.env.REACT_APP_GATEWAY_HOST

type GET_API_PROPS =
  | {
      isPublic?: boolean
      noPrefix?: boolean
    }
  | undefined

const getApi = async ({
  isPublic = false,
  noPrefix = false,
}: GET_API_PROPS = {}) => {
  const accessToken = isPublic ? undefined : getValidPFToken()
  const instance = axios.create({
    baseURL: HOST + (noPrefix ? '' : '/api'),
    headers: {
      'Access-Control-Allow-Origin': '*',
      clientType: 'web',
    },
  })

  if (accessToken) {
    instance.defaults.headers.common['authorization'] = `Bearer ${accessToken}`
  }

  if (!isPublic) {
    instance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        // if (
        //   error?.response?.status === 401 &&
        //   window.location.href.indexOf("/login") === -1
        // ) {
        //   clearPFToken();
        //   window.location.href = "/";
        // }

        return Promise.reject(error)
      }
    )
  }
  return instance
}

export default {
  getApi,
}
