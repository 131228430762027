import clsx from 'classnames'
import { ForwardedRef, createElement, forwardRef } from 'react'

import { ReactComponent as avatar } from 'assets/icons/avatar.svg'
import { ReactComponent as back } from 'assets/icons/back.svg'
import { ReactComponent as close } from 'assets/icons/close.svg'
import { ReactComponent as download } from 'assets/icons/download.svg'
import { ReactComponent as loveLetter } from 'assets/icons/love-letter.svg'
import { ReactComponent as question } from 'assets/icons/question.svg'

import styles from './index.module.scss'

const IconMap = {
  back,
  download,
  loveLetter,
  question,
  avatar,
  close,
}
export type FPIconKey = keyof typeof IconMap

export interface FPIconProps {
  type: FPIconKey
  className?: string
  spin?: boolean
  onClick?: () => void
}

export const FPIcon = forwardRef(
  (props: FPIconProps, ref: ForwardedRef<SVGSVGElement>) => {
    if (!IconMap[props.type]) return null
    return createElement(IconMap[props.type], {
      ...props,
      ref,
      className: clsx(styles.container, 'fp-icon', props.className),
    })
  }
)
