import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { amplitudeInit } from 'utils/amplitude'
import App from './App'
import './index.scss'

window.isDev = /^localhost:300\d$/.test(window.location.host)
amplitudeInit()
console.log('name:', process.env.REACT_APP_NAME)
console.log('version:', process.env.REACT_APP_VERSION)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
