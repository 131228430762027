import { Button, Modal } from 'antd'
import { LoadingButton } from 'components/LoadingButton'
import classnames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getTemplatePromise } from 'apis/admin'
import { onExportCSV } from 'utils/csv'
import { FPIcon } from 'components/FPIcon'
import { logAmplitudeEvent } from 'utils/amplitude'

import styles from './Upload.module.scss'
import { type RecentUpdates, type UploadFile, type UploadStatus } from './types'

interface UploadViewProps {
  file?: UploadFile
  setFile: (f: UploadFile) => void
  chooseFile: () => void
  onSelectFile: () => void
  uploadRef: React.RefObject<HTMLInputElement>

  uploadStatus: UploadStatus
  recentUpdates: RecentUpdates
}

export const UploadView: React.FC<UploadViewProps> = ({
  chooseFile,
  onSelectFile,
  uploadRef,
  uploadStatus,
  recentUpdates,
}) => {
  const [showInstructions, setShowInstructions] = useState<boolean>(false)
  useEffect(() => {
    if (uploadStatus?.uploadError) {
      logAmplitudeEvent('Screen View: FPP - Blocking Errors', {
        Errors: uploadStatus?.uploadErrorCode,
      })
    } else {
      logAmplitudeEvent('Screen View: FPP - Home')
    }
  }, [uploadStatus?.uploadError, uploadStatus?.uploadErrorCode])

  const getTemplate = useCallback(async () => {
    logAmplitudeEvent('Click: FPP Home - Get Template')
    const columns = await getTemplatePromise()
    onExportCSV(columns, 'FitOn Member check-ins')
  }, [])
  return (
    <>
      <div className={styles.uploadView}>
        <div className='top-sec'>
          <div className='title'>Upload your list of member check-ins </div>
          <div className='description'>
            Add FitOn Member check-ins so we can reimburse you.{' '}
            <span
              className='instruction-link'
              onClick={() => setShowInstructions(true)}
            >
              View instructions
            </span>
          </div>
          <div className='button-sec'>
            <LoadingButton className='btn-cancel big' onClick={getTemplate}>
              Get template
            </LoadingButton>
            <Button
              className='btn-primary big'
              onClick={() => {
                if (uploadStatus.uploadError) {
                  logAmplitudeEvent(
                    'Click: FPP Upload Preview - Upload New File',
                    {}
                  )
                } else {
                  logAmplitudeEvent('Click: FPP Home - Upload File')
                }

                chooseFile()
              }}
            >
              Upload file
            </Button>
            <input
              ref={uploadRef}
              type='file'
              accept='.csv'
              name='upload'
              id='upload'
              onChange={onSelectFile}
              style={{ display: 'none' }}
              hidden
            />
          </div>
        </div>
        {!!uploadStatus?.uploadError && (
          <div className='upload-error-sec'>
            <div className='title'>
              Errors in the CSV file prevented the upload
            </div>
            <div className='info'>
              Please double check the headers, CSV file format, the size is 10
              MB or less, and the file name does NOT contain special characters
              like #, %, &, {`{ }`}, and \ before retrying. We’re here to help!
              If you have any questions, please see our{' '}
              <a
                className='email gradient-font-1'
                target='_blank'
                href='https://help.fitonhealth.com/network'
                rel='noreferrer'
              >
                Help Center
              </a>{' '}
              for assistance.
            </div>
          </div>
        )}
        {!!recentUpdates?.length && (
          <div className='recent-updates-sec'>
            <div className='title'>Recent updates</div>
            <table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Uploader</th>
                  <th>File name</th>
                  <th>Unique Members</th>
                </tr>
                {recentUpdates.map((item) => (
                  <tr className={styles.itemTr} key={item.id}>
                    <td className={styles.noWrap}>
                      {new Date(item.importStartDate).toLocaleString()}
                    </td>
                    <td>{item.uploader}</td>
                    <td>{item.fileName}</td>
                    <td>{item.uniqueMembersCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal
        title={null}
        footer={null}
        closable={false}
        open={showInstructions}
        className={classnames(styles.modal)}
        centered={true}
        maskTransitionName=''
        transitionName=''
        width={920}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <FPIcon
            type='close'
            className='close'
            onClick={() => {
              setShowInstructions(false)
            }}
          />
          <div className='title'>Upload instructions</div>
          {[
            'Download the CSV template. Add member check-ins to this file, one per row, per day. Make sure to complete every column of information for each member check-in row.',
            'Save the completed template as a CSV file and then return to this portal and select to Upload File. Then use the wizard to select the CSV file containing your member check-ins.',
            'Your member check-in file will be uploaded into a pending state where you can confirm the check-in and member totals and select to Update (or Discard).',
            'If your file contains errors select to Download Error File to make corrections and re-upload. You can also Apply Excluding Errors to ignore these rows and upload just the rows without errors.',
          ].map((text, index) => (
            <div className='instruction' key={index}>
              <div className='index'>
                <div className='inner'>{index + 1}</div>
              </div>
              <div className='text'>{text}</div>
            </div>
          ))}
          <div className='instruction-bottom'>
            <div className='text'>
              Once you upload the file we will start the reimbursement process
              for the specific set of valid check-ins. All unique members
              submitted are not guaranteed reimbursement and will be subject to
              final review by FitOn.
            </div>
          </div>
          {/* <a
            target='_blank'
            className='instruction-link'
            rel='noreferrer'
            href='https://www.fitonhealth.com/faq/how-do-i-upload-my-eligibles'
          >
            Learn how to upload your member check-in data
          </a> */}
        </div>
      </Modal>
    </>
  )
}
