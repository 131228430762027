import Joi from 'joi'

export const MailModel = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: false })
    .required()
    .error(
      () => new Error(`Please fix your email so it's a valid email address.`)
    ),
})

export const validateEmail = (email: string): undefined | string => {
  const result = MailModel.validate({ email })
  console.log(result.error)
  return result.error?.message
}
