import { Button, ButtonProps } from 'antd'
import React, { useState } from 'react'

export const LoadingButton = (props:ButtonProps) => {
  const [loading, setLoading] = useState(false)

  const click = async e => {
    if (loading) return
    setLoading(true)
    await props.onClick(e)
    setLoading(false)
  }
  return (
    <Button {...props} onClick={click} disabled={loading || props.disabled}>
      {props.children}
    </Button>
  )
}

