import { Button, Modal } from "antd";
import { Progress } from "antd";
import classnames from "classnames";
import React, { useCallback, useRef, useState } from "react";

import { FPIcon } from "components/FPIcon";

import styles from "./Upload.module.scss";
import checkSvg from "./images/check.svg";
import fileSvg from "./images/file.svg";
import { type UploadFile, type UploadStatus } from "./types";

interface UploadingViewProps {
  onClose: () => void;
  file?: UploadFile;
  uploadProgress: number;
  uploadStatus: UploadStatus;
}

export const UploadingView: React.FC<UploadingViewProps> = ({
  onClose,
  uploadProgress,
  file,
  uploadStatus,
}) => {
  return (
    <div className={styles.uploadingView}>
      <div className="main-sec">
        <div className="title">Uploading...</div>
        <FPIcon type="close" className="close" onClick={onClose} />
        <div className="progress-sec">
          <div className="left-sec">
            <img src={fileSvg} alt="" className="" />
            <div className="file-info">
              <div className="name">{file?.name}</div>
              <div className="size">{file?.size}</div>
            </div>
          </div>
          {!uploadStatus.pendingFile ? (
            <Progress
              type="circle"
              percent={uploadProgress}
              size={46}
              strokeWidth={8}
              className="progress-indicator"
              strokeColor={{ "0%": "#F47253", "100%": "#E03694" }}
            />
          ) : (
            <img src={checkSvg} alt="" className="" />
          )}
        </div>
      </div>
    </div>
  );
};
