import { Button, Modal } from 'antd'
import { Progress } from 'antd'
import classnames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { notification } from 'antd'
import { logAmplitudeEvent } from 'utils/amplitude'

import { FPIcon } from 'components/FPIcon'
import { getUploadErrorPromise } from 'apis/admin'
import styles from './Upload.module.scss'
import checkSvg from './images/check.svg'
import { onExportCSV } from 'utils/csv'
import { type UploadFile, type UploadStatus } from './types'

interface UploadingViewProps {
  onClose: () => void
  file?: UploadFile
  chooseFile: () => void
  onCommitFile: () => void
  uploadStatus: UploadStatus
  fileCommitDone: boolean
  fileCommitProgress: number
}

const Description = () => (
  <div className='description-sec'>
    All unique members submitted are not guaranteed reimbursement and will be
    subject to final review by FitOn.
  </div>
)

export const PreviewView: React.FC<UploadingViewProps> = ({
  onClose,
  chooseFile,
  file,
  fileCommitProgress,
  fileCommitDone,
  onCommitFile,
  uploadStatus,
}) => {
  const errors = uploadStatus?.pendingFile?.errorCount
  const [downloading, setDownloading] = useState<boolean>(false)
  useEffect(() => {
    if (fileCommitDone) {
      logAmplitudeEvent('Screen View: FPP - Upload Success', {
        'Unique members': uploadStatus?.pendingFile?.uniqueMembersCount,
        'Check-ins': uploadStatus?.pendingFile?.totalCount,
      })
    } else {
      logAmplitudeEvent('Screen View: FPP - Upload Preview', {
        'Unique members': uploadStatus?.pendingFile?.uniqueMembersCount,
        Errors: errors,
        'Check-ins': uploadStatus?.pendingFile?.totalCount,
      })
    }
  }, [
    errors,
    uploadStatus?.pendingFile?.totalCount,
    uploadStatus?.pendingFile?.uniqueMembersCount,
    fileCommitDone,
  ])

  const onDownloadError = async () => {
    if (downloading) return
    setDownloading(true)
    try {
      const url = uploadStatus?.pendingFile?.errorReportUrl
      const columns = await getUploadErrorPromise(url?.replace('/api/pf', ''))
      onExportCSV(columns, 'error report')
    } catch (e) {
      notification['error']({
        message: 'Error',
        description: 'Download failed',
      })
    }
    setDownloading(false)
  }

  const hasError = !!errors && !fileCommitDone
  return (
    <div className={styles.previewView}>
      <div className='main-sec'>
        <FPIcon type='close' className='close' onClick={onClose} />
        <div className='top-sec'>
          {fileCommitProgress < 1 ? (
            <div className='title'>Preview</div>
          ) : (
            <div className='progress-sec'>
              {!fileCommitDone ? (
                <>
                  <Progress
                    type='circle'
                    percent={fileCommitProgress}
                    size={46}
                    strokeWidth={8}
                    className='progress-indicator'
                    strokeColor={{ '0%': '#F47253', '100%': '#E03694' }}
                  />
                  <div className='title'>Your program is being updated...</div>
                </>
              ) : (
                <>
                  <img src={checkSvg} alt='' className='' />
                  <div className='title'>Your update is complete.</div>
                </>
              )}
            </div>
          )}
        </div>
        <div className='table-sec'>
          {[
            {
              label: 'UPDATE',
              value: file?.name,
              className: 'table-header',
            },

            {
              label: 'Total check-ins ',
              value: uploadStatus?.pendingFile?.totalCount,
              className: 'table-body',
            },
            {
              label: 'Unique members',
              value: uploadStatus?.pendingFile?.uniqueMembersCount,
              className: 'table-body',
            },
            {
              label: 'Errors',
              value: errors,
              className: classnames('table-body', {
                error: !!errors,
              }),
            },
          ]?.map(({ className, label, value }) => (
            <div key={label} className={className}>
              <div className='label'>{label}</div>
              <div className='value'>{value}</div>
            </div>
          ))}
        </div>
        {!hasError && <Description />}

        {hasError && (
          <>
            <div className='error-sec'>
              <div className='detail'>{`${errors} error${
                errors > 1 ? 's' : ''
              }`}</div>
              <div className='download' onClick={onDownloadError}>
                <FPIcon type='download' />
                Download error file
              </div>
            </div>

            <div className='apply-update-sec'>
              <Description />
              Apply the update, excluding the errors or Upload a new file?
            </div>
          </>
        )}
        <div className='button-sec'>
          {fileCommitDone ? (
            <Button
              className='btn-primary big done-btn'
              onClick={() => {
                onClose()
              }}
            >
              Done
            </Button>
          ) : !!errors ? (
            <>
              <Button
                className='btn-cancel big'
                onClick={() => {
                  logAmplitudeEvent('Click: FPP - Upload Preview', {
                    Action: 'Apply Excluding Errors',
                    'Unique members':
                      uploadStatus?.pendingFile?.uniqueMembersCount,
                    Errors: errors,
                    'Check-ins': uploadStatus?.pendingFile?.totalCount,
                  })
                  onCommitFile()
                }}
              >
                Apply Excluding Errors
              </Button>
              <Button
                className='btn-primary big'
                onClick={() => {
                  logAmplitudeEvent('Click: FPP - Upload Preview', {
                    Action: 'Cancel',
                    'Unique members':
                      uploadStatus?.pendingFile?.uniqueMembersCount,
                    Errors: errors,
                    'Check-ins': uploadStatus?.pendingFile?.totalCount,
                  })
                  onClose()
                }}
              >
                Upload New File
              </Button>
            </>
          ) : (
            <>
              <Button
                className='btn-cancel big'
                onClick={() => {
                  logAmplitudeEvent('Click: FPP - Upload Preview', {
                    Action: 'Cancel',
                    'Unique members':
                      uploadStatus?.pendingFile?.uniqueMembersCount,
                    Errors: errors,
                    'Check-ins': uploadStatus?.pendingFile?.totalCount,
                  })
                  onClose()
                }}
              >
                Discard
              </Button>
              <Button
                className='btn-primary big'
                onClick={() => {
                  logAmplitudeEvent('Click: FPP - Upload Preview', {
                    Action: 'Update',
                    'Unique members':
                      uploadStatus?.pendingFile?.uniqueMembersCount,
                    Errors: errors,
                    'Check-ins': uploadStatus?.pendingFile?.totalCount,
                  })
                  onCommitFile()
                }}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
