import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const noHeaderUrls = [/\/login/]

export const formatPathName = (pathname: string) =>
  pathname.split('/')[1].toLowerCase()

const hasUrlFullMatch = (list: RegExp[], pathname: string) => {
  return !!_.find(list, (url: RegExp) => {
    let hasUrl = false
    hasUrl = url.test(pathname)
    return hasUrl
  })
}

export function usePagePathType() {
  const [pathType, setPathType] = useState({ header: true })
  const pathname = useLocation().pathname

  useEffect(() => {
    flow()
  }, [pathname])

  const flow = () => {
    setPathType({
      header: hasUrlFullMatch(noHeaderUrls, pathname) ? false : true,
    })
  }
  return pathType
}
