import { AuthContextProvider } from "contexts/AuthContext";
import { usePagePathType } from "usePagePathType";

import { Header } from "components/Header";

import styles from "./App.module.scss";
import Routers from "./Routers";

const App = () => {
  const pathType = usePagePathType();

  return (
    <div className={styles.container}>
      {pathType.header && <Header></Header>}
      <Routers></Routers>
    </div>
  );
};

export default AuthContextProvider(<App />);
