import { notification } from 'antd'
import { getLoginEmailPromise } from 'apis/admin'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { FPIcon } from 'components/FPIcon'
import { FPInput } from 'components/FPInput'
import { LoadingButton } from 'components/LoadingButton'
import { logAmplitudeEvent } from 'utils/amplitude'
import logoImg from 'assets/images/logo.svg'

import { validateEmail } from 'utils/validators'

import styles from './Login.module.scss'
import img_1 from './images/img-1.png'
import img_2 from './images/img-2.png'
import img_3 from './images/img-3.png'

interface LoginProps {}

export const Login: React.FC<LoginProps> = (props) => {
  const [email, setEmail] = useState<string>('')
  const { utm_source = '' } = queryString.parse(window.location.search, {
    decode: false,
  })
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  useEffect(() => {
    if (showSuccess) {
      logAmplitudeEvent('Screen View: Check Your Email', { Email: email })
    } else {
      logAmplitudeEvent('Screen View: FPP - Log In', { Source: utm_source })
    }
  }, [utm_source, showSuccess])

  const onEmailLogin = async (e: React.FormEvent, resend = false) => {
    e.preventDefault()
    const errorMsg = validateEmail(email)
    if (errorMsg) {
      notification['error']({
        message: 'Error',
        description: errorMsg,
      })
      if (resend) {
        logAmplitudeEvent('Click: FPP Check Your Email - Resend Email')
      } else {
        logAmplitudeEvent('Click: FPP Log In - Continue', {
          Result: 'Fail',
          'Error Message': errorMsg,
          'Error Code': '',
        })
      }
    } else {
      try {
        await getLoginEmailPromise(email)
        notification['success']({
          message: 'success',
        })
        if (resend) {
          logAmplitudeEvent('Click: FPP Check Your Email - Resend Email')
        } else {
          logAmplitudeEvent('Click: FPP Log In - Continue', {
            Result: 'Success',
          })
        }

        setShowSuccess(true)
      } catch (e: any) {
        const msg = e?.response?.data?.Message ?? e?.response?.data ?? 'Error'
        console.log(e)
        notification['error']({
          message: 'Error',
          description: msg,
        })
        if (resend) {
          logAmplitudeEvent('Click: FPP Check Your Email - Resend Email')
        } else {
          logAmplitudeEvent('Click: FPP Log In - Continue', {
            Result: 'Fail',
            'Error Message': msg,
            'Error Code': e?.response?.data?.Code,
          })
        }
      }
    }
  }

  return (
    <div className={styles.container}>
      <Helmet title={'Log In'} />
      <img src={logoImg} alt='' className='logo' />
      <div className='login-sec'>
        <div className='left-sec'>
          <img src={img_1} alt='' className='' />
          <img src={img_2} alt='' className='' />
          <img src={img_3} alt='' className='' />
        </div>
        {!showSuccess ? (
          <div className='right-sec'>
            <div className='login-title'>Log into FitOn Health</div>
            <div className='login-description'>
              Sign in with your email address.
            </div>
            <form onSubmit={onEmailLogin}>
              <FPInput
                placeholder='Email'
                value={email}
                onChange={(value) => setEmail(value as string)}
                darkStyle={true}
              />
              <LoadingButton
                htmlType='submit'
                className='btn-primary submit-btn'
                onClick={onEmailLogin}
              >
                Continue
              </LoadingButton>
            </form>
          </div>
        ) : (
          <div className='right-sec success-sec'>
            <FPIcon
              type='back'
              className='back-icon'
              onClick={() => setShowSuccess(false)}
            ></FPIcon>
            <div></div>
            <div className='center-sec'>
              <FPIcon type='loveLetter' className='letter-icon'></FPIcon>
              <div className='success-title'>Check your email!</div>
              <div className='success-info'>
                If eligible, an activation link was sent to{' '}
                <span className='gradient-font-1'>{email}</span>. Open this link
                to start using your FitOn Health portal.
              </div>
            </div>
            <div className='resend-sec'>
              Didn&apos;t receive an email? Check your <br /> spam folder or tap
              to{' '}
              <span
                className='hover-link'
                onClick={(e) => {
                  onEmailLogin(e, true)
                }}
              >
                resend email
              </span>
              .
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
