import { Popover } from 'antd'
import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { FPIcon } from 'components/FPIcon'
import { clearPFToken } from 'utils/TokenHelper'
import logoImg from 'assets/images/logo.svg'

import styles from './index.module.scss'

export const Header = () => {
  const navigate = useNavigate()
  const [exitOpen, setExitOpen] = useState<boolean>(false)
  const logout = () => {
    setExitOpen(false)
    clearPFToken()
    navigate(`/login`)
  }
  return (
    <header className={styles.container}>
      <img src={logoImg} alt='' className='logo' />
      <div className='right-sec'>
        <a
          className='faq'
          target='_blank'
          href='https://help.fitonhealth.com/network/how-to-submit-check-in-data'
          rel='noreferrer'
        >
          <FPIcon type='question' className='faq-icon'></FPIcon>
          <div className={styles.faqText}>FAQ</div>
        </a>

        <Popover
          content={
            <div className='exit-admin' onClick={logout}>
              Log out
            </div>
          }
          trigger='click'
          arrow={false}
          overlayClassName={styles.popover}
          open={exitOpen}
          onOpenChange={(value) => setExitOpen(value)}
        >
          <FPIcon type='avatar' className='avatar-icon'></FPIcon>
        </Popover>
      </div>
    </header>
  )
}
