import classnames from 'classnames'
import React from 'react'

import styles from './FPInput.module.scss'

interface FPInputProps {
  placeholder?: string
  className?: string
  value: string | number
  onChange: (value: string | number) => void
  darkStyle?: boolean
  type?: string
  disabled?: boolean
}

export const FPInput: React.FC<FPInputProps> = ({
  value = '',
  placeholder = '',
  className = '',
  onChange,
  darkStyle = false,
  type = '',
  disabled = false,
}) => {
  return (
    <div className={classnames(styles.container, className, 'fp-input')}>
      <div
        className={classnames(
          darkStyle ? 'dark-input-sec' : 'white-input-sec',
          {
            'has-value': value,
          }
        )}
      >
        <input
          placeholder={placeholder}
          type={type}
          onChange={e => onChange(e.target.value)}
          value={value}
          autoComplete="on"
          disabled={disabled}
        />
      </div>
    </div>
  )
}
